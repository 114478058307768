window['$'] = window['jQuery'] = require('jquery')
import 'bootstrap'

import * as React from 'react'
import { render } from 'react-dom'

import App from './containers/App'

const el = document.getElementById('app')
if (el) {
  render(<App />, document.getElementById('app'))
}
